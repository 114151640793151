<template comments>
  <div class="user-form">
    <div class="row">
      <div class="col-5">
        <form>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Full name</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="name" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Pronouns</label>
            <div class="col-sm-8">
              <select class="form-control" v-model="pronouns">
                <option value="">None</option>
                <option value="She/Her">She/Her</option>
                <option value="He/Him">He/Him</option>
                <option value="They/Them">They/Them</option>
                <option value="Custom">Custom</option>
              </select>
              <input
                type="text"
                placeholder="Custom pronoun"
                class="mt-1 form-control"
                v-model="pronounsCustom"
                v-if="pronouns == 'Custom'"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Role</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="role" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Business unit</label>
            <div class="col-sm-8">
              <select class="form-control" v-model="unit">
                <option value="ICON">ICON</option>
                <option value="REP">Icon Reputation</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Office</label>
            <div class="col-sm-8">
              <select class="form-control" v-model="office">
                <option value="brisbane">Brisbane</option>
                <option value="canberra">Canberra</option>
                <option value="melbourne">Melbourne</option>
                <option value="sydney">Sydney</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Email</label>
            <div class="col-sm-8">
              <input type="email" class="form-control" v-model="email" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Phone</label>
            <div class="col-sm-8">
              <input type="tel" class="form-control" v-model="phone" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Phone Ext.</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" v-model="ext" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Mobile</label>
            <div class="col-sm-8">
              <input type="tel" class="form-control" v-model="mobile" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Custom note</label>
            <div class="col-sm-8">
              <input type="email" class="form-control" v-model="customNote" placeholder="e.g. Availability" />
            </div>
          </div>
          <div class="form-check" v-if="isAgency">
            <input class="form-check-input" type="checkbox" v-model="awards" id="showAwards" />
            <label class="form-check-label" for="showAwards">Show awards</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="images" id="useImages" />
            <label class="form-check-label" for="useImages">Use images</label>
          </div>
        </form>
      </div>
      <div class="col-7">
        <Signature
          id="email-signature"
          class="rounded border p-5"
          ref="output"
          :name="name"
          :pronouns="pronouns == 'Custom' ? pronounsCustom : pronouns"
          :role="role"
          :customNote="customNote"
          :email="email"
          :phone="phone"
          :mobile="mobile"
          :ext="ext"
          :office="office"
          :unit="unit"
          :awards="awards"
          :images="images"
        />
        <div class="mt-3 text-right">
          <button @click.prevent="copy" type="button" class="btn btn-outline-secondary btn-sm mr-2">
            <font-awesome-icon icon="copy" /> Copy HTML
          </button>
          <button @click.prevent="select" type="button" class="btn btn-primary btn-sm">
            <font-awesome-icon icon="copy" /> Select all for copy into mail program
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Signature from './Signature'

export default {
  name: 'UserForm',

  components: {
    Signature,
  },

  data() {
    return {
      name: 'Name Surname',
      pronouns: '',
      customNote: null,
      role: 'Account Manager',
      email: 'name.surname@iconagency.com.au',
      phone: '03 9642 4107',
      mobile: '',
      ext: '',
      office: 'melbourne',
      unit: 'ICON',
      awards: true,
      images: true,
      pronounsCustom: null,
    }
  },

  computed: {
    isAgency() {
      return this.unit === 'ICON'
    },
  },

  methods: {
    select() {
      let sel, range
      const el = this.$refs.output.$el // get element id
      if (window.getSelection && document.createRange) {
        // Browser compatibility
        sel = window.getSelection()
        if (sel.toString() === '') {
          // no text selection
          window.setTimeout(function () {
            range = document.createRange() // range object
            range.selectNodeContents(el) // sets Range
            sel.removeAllRanges() // remove all ranges from selection
            sel.addRange(range) // add Range to a Selection.
          }, 1)
        }
      }
    },

    copy() {
      const aux = document.createElement('input')
      aux.setAttribute('value', this.$refs.output.$el.innerHTML.replace(/&quot;/g, "'"))
      document.body.appendChild(aux)
      aux.select()
      document.execCommand('copy')
      document.body.removeChild(aux)

      this.$toasted.success('Copied to clipboard.', {
        duration: 2000,
        fitToScreen: true,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
label {
  font-family: Arial, Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}
</style>

<template>
  <div id="app">
    <div class="container">
      <div class="row my-5">
        <div class="col">
          <h1 class="m-0">ICON Email Signature (2024)</h1>
        </div>
      </div>

      <p v-if="isSafari">
        Sorry, this generator wont work with your browser. Safari has issues copy pasting into mail clients.
        <strong>Reopen this page in Chrome</strong>
      </p>
      <UserForm class="my-5" v-else />
    </div>

    <div class="container my-5 pt-5 border-top">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              width="1280"
              height="720"
              src="https://www.youtube.com/embed/x-pATsvrQQc"
              title="Installing your email signature on Apple mail on macOS High Sierra."
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserForm from './components/UserForm.vue'

export default {
  name: 'App',

  components: {
    UserForm,
  },

  data() {
    return {
      isSafari: false,
    }
  },

  mounted() {
    const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/)

    if (isSafari) {
      this.isSafari = true
    }
  },
}
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/utilities';
</style>

<style lang="scss" scoped>
h1 {
  font-family: Arial, Helvetica, sans-serif;
}
</style>

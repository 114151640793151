<template>
  <div>
    <br />
    <!-- Contact details -->
    <table
      role="presentation"
      border="0"
      cellpadding="0"
      cellspacing="0"
      width="100%"
      style="color: inherit; font-family: Arial, Helvetica, sans-serif; border-collapse: collapse; border-spacing: 0"
    >
      <tr>
        <td class="name">
          <font size="3" face="Arial" class="name">
            {{ name }}
            <font size="2" face="Arial" class="default" v-if="pronouns">({{ pronouns }})</font>
          </font>
        </td>
      </tr>

      <tr v-if="role">
        <td
          height="4"
          style="
            font-size: 0px;
            line-height: 0px;
            mso-line-height-rule: exactly;
            height: 4px;
            overflow: hidden;
            margin: 0;
          "
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr v-if="role">
        <td class="default">
          <font size="2" face="Arial" class="default">
            <b>{{ role }}</b>
          </font>
        </td>
      </tr>

      <tr>
        <td
          height="25"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 25px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr v-if="emailValid">
        <td class="default">
          <font size="2" face="Arial" class="default">
            <a :href="`mailto:${email.toLowerCase()}`" class="default-link">
              <font color="#000000" style="color: inherit">{{ email.toLowerCase() }}</font>
            </a>
          </font>
        </td>
      </tr>

      <tr v-if="telDisplay && telUri">
        <td
          height="6"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 6px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr v-if="telDisplay && telUri">
        <td class="default">
          <font size="2" face="Arial" class="default">
            <a :href="`${telUri}`" class="default-link">
              <font color="#000000" style="color: inherit">{{ telDisplay }}</font>
            </a>
            <template size="2" face="Arial" v-if="ext"> ext. {{ ext }}</template>
          </font>
        </td>
      </tr>

      <tr v-if="mobDisplay && mobUri">
        <td
          height="6"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 6px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr v-if="mobDisplay && mobUri">
        <td class="default">
          <font size="2" face="Arial" class="default">
            <a :href="`${mobUri}`" class="default-link">
              <font color="#000000" style="color: inherit">{{ mobDisplay }}</font>
            </a>
          </font>
        </td>
      </tr>

      <tr v-if="customNote">
        <td
          height="8"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 8px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr v-if="customNote">
        <td class="small">
          <font size="1" face="Arial" class="small" style="font-style: italic">
            <i>{{ customNote }}</i>
          </font>
        </td>
      </tr>

      <tr>
        <td
          height="25"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 25px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr>
        <td class="default">
          <font size="2" face="Arial" class="default">
            <b>I acknowledge the Traditional Owners of the land on which I live, learn and work</b>
          </font>
        </td>
      </tr>

      <tr>
        <td
          height="6"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 6px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr>
        <td class="default">
          <font size="2" face="Arial" class="default">
            <template v-if="office == 'sydney'">
              <a href="https://g.page/icon-agency-sydney?share" class="address-link">
                <font color="#000000" style="color: inherit">
                  Suite 3-4, Level 6, 2-12 Foveaux Street, Surry Hills NSW 2010
                </font>
              </a>
            </template>
            <template v-else-if="office == 'canberra'">
              <a href="https://goo.gl/maps/UU23EejpNvacaRG29" class="address-link">
                <font color="#000000" style="color: inherit"> Suite 4, 32 Thesiger Ct, Deakin ACT 2600 Australia </font>
              </a>
            </template>
            <template v-else-if="office == 'brisbane'">
              <a href="https://goo.gl/maps/Y3nAornePTugNVRR9" class="address-link">
                <font color="#000000" style="color: inherit">
                  25 King Street Bowen Hills Brisbane QLD 4006 Australia
                </font>
              </a>
            </template>
            <template v-else>
              <a href="https://goo.gl/maps/iJsWj3fLa88squkE6" class="address-link">
                <font color="#000000" style="color: inherit"> 132C Gwynne Street, Cremorne VIC 3121 Australia </font>
              </a>
            </template>
          </font>
        </td>
      </tr>

      <tr v-if="images">
        <td
          height="15"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 12px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr v-else>
        <td
          height="25"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 25px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr v-if="isAgency && images">
        <td class="default">
          <a
            href="https://iconagency.com.au"
            style="text-decoration: none; border: none; outline: none; display: block"
          >
            <img
              :src="`${host}/logo-agency-2024.png`"
              width="165"
              height="66"
              alt="ICON"
              style="max-width: 100%; max-height: 66px; height: auto; display: block; border: none"
              border="0"
            />
          </a>
        </td>
      </tr>

      <tr v-else-if="isReputation && images">
        <td class="default">
          <a
            href="https://iconreputation.com.au"
            style="text-decoration: none; border: none; outline: none; display: block"
          >
            <img
              :src="`${host}/logo-reputation-2024.png`"
              width="154"
              height="71"
              alt="ICON REPUTATION"
              style="max-width: 100%; max-height: 71px; height: auto; display: block; border: none"
              border="0"
            />
          </a>
        </td>
      </tr>

      <tr v-if="images">
        <td
          height="10"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 10px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr>
        <td class="default">
          <font size="2" face="Arial" class="default">
            <b>MELBOURNE / SYDNEY / CANBERRA / BRISBANE / FIJI</b>
          </font>
        </td>
      </tr>

      <tr>
        <td
          height="4"
          style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 4px; overflow: hidden"
        >
          <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
        </td>
      </tr>

      <tr>
        <td class="small">
          <font size="1" face="Arial" class="small">
            <template v-if="isReputation">
              <a href="https://iconreputation.com.au" class="small-link">
                <font color="#000000" style="color: inherit">iconreputation.com.au</font>
              </a>
              /
              <a href="https://www.linkedin.com/company/icon-reputation" class="small-link">
                <font color="#000000" style="color: inherit">linkedin</font>
              </a>
            </template>

            <template v-else>
              <a href="https://iconagency.com.au" class="small-link">
                <font color="#000000" style="color: inherit">iconagency.com.au</font>
              </a>
              /
              <a href="https://www.linkedin.com/company/iconagency" class="small-link">
                <font color="#000000" style="color: inherit">linkedin</font>
              </a>
              /
              <a href="https://www.instagram.com/icon_agency/" class="small-link">
                <font color="#000000" style="color: inherit">instagram</font>
              </a>
            </template>
          </font>
        </td>
      </tr>

      <template v-if="awards && isAgency">
        <tr>
          <td
            height="25"
            style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 25px; overflow: hidden"
          >
            <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
          </td>
        </tr>

        <tr>
          <td class="small">
            <font size="1" face="Arial" class="small">
              <b>AWARDS</b>
            </font>
          </td>
        </tr>

        <tr>
          <td
            height="4"
            style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly; height: 4px; overflow: hidden"
          >
            <font size="0" style="font-size: 0px; line-height: 0px; mso-line-height-rule: exactly">&nbsp;</font>
          </td>
        </tr>

        <tr>
          <td class="small small-line-height">
            <font size="1" face="Arial" class="small small-line-height">
              Global PR Agency of the Year - CAMPAIGN GLOBAL AOY 2023 (Silver)<br />
              Digital PR Agency of the Year - GLOBAL SABRES 2022 (Winner)<br />
              Australian PR Agency of the Year - CAMPAIGN APAC Awards 2023 (Silver), 2022 & 2021 (Gold)<br />
              Large Agency of the Year - CPRA GTAs 2023 (Bronze), 2021 & 2022 (Gold)<br />
              Design/UX Award Winner, Space.gov.au - DRUPAL SOUTH SPLASH AWARDS, 2024<br />
              <br />
              <a href="https://iconagency.com.au/news?filters=18" class="small-link">
                <font color="#000000" style="color: inherit">See all our awards</font>
              </a>
            </font>
          </td>
        </tr>
      </template>
    </table>

    <br />
  </div>
</template>

<script>
import * as EmailValidator from 'email-validator'
import parsePhoneNumber from 'libphonenumber-js'

export default {
  name: 'Signature',

  props: {
    name: null,
    pronouns: null,
    role: null,
    customNote: null,
    email: null,
    phone: null,
    mobile: null,
    ext: null,
    office: null,
    unit: null,
    awards: null,
    images: null,
  },

  computed: {
    telUri() {
      return this.telFmt ? this.telFmt.getURI() : null
    },

    telDisplay() {
      return this.telFmt ? (this.telFmt.isValid() ? this.telFmt.formatInternational() : null) : null
    },

    telFmt() {
      return parsePhoneNumber(this.phone.replace(/[^\d]/g, ''), 'AU')
    },

    mobUri() {
      return this.mobFmt ? this.mobFmt.getURI() : null
    },

    mobDisplay() {
      return this.mobFmt ? (this.mobFmt.isValid() ? this.mobFmt.formatInternational() : null) : null
    },

    mobFmt() {
      return parsePhoneNumber(this.mobile.replace(/[^\d]/g, ''), 'AU')
    },

    emailValid() {
      return EmailValidator.validate(this.email)
    },

    isAgency() {
      return this.unit === 'ICON'
    },

    isReputation() {
      return this.unit === 'REP'
    },

    host() {
      const host = window.location.port.match(/808[0-9]/)
        ? `${window.location.hostname}:${window.location.port}`
        : window.location.hostname

      return `${window.location.protocol}//${host}`
    },
  },

  updated() {
    this.applyStyles()
  },

  mounted() {
    this.applyStyles()
  },

  methods: {
    // Vue will strip invalid mso css. We do this dirty. Fuckin stupid I know. Its for the greater good.
    applyStyles() {
      if (!this.$el) {
        return
      }

      Array.from(this.$el.querySelectorAll('.name')).forEach((element) => {
        this.applyStyle('name', this.styles.name, element)
      })

      Array.from(this.$el.querySelectorAll('.default')).forEach((element) => {
        this.applyStyle('default', this.styles.default, element)
      })

      Array.from(this.$el.querySelectorAll('.defaultSerif')).forEach((element) => {
        this.applyStyle('defaultSerif', this.styles.defaultSerif, element)
      })

      Array.from(this.$el.querySelectorAll('.small')).forEach((element) => {
        this.applyStyle('small', this.styles.small, element)
      })

      Array.from(this.$el.querySelectorAll('.small-line-height')).forEach((element) => {
        this.applyStyle('small-line-height', this.styles.smallLineHeight, element)
      })

      Array.from(this.$el.querySelectorAll('.default-link')).forEach((element) => {
        this.applyStyle('default-link', this.styles.defaultLink, element)
      })

      Array.from(this.$el.querySelectorAll('.small-link')).forEach((element) => {
        this.applyStyle('small-link', this.styles.smallLink, element)
      })

      Array.from(this.$el.querySelectorAll('.address-link')).forEach((element) => {
        this.applyStyle('address-link', this.styles.addressLink, element)
      })

      Array.from(this.$el.querySelectorAll('.image-circle')).forEach((element) => {
        this.applyStyle('image-circle', this.styles.imgCircle, element)
      })
    },

    applyStyle(className, styleObject, element) {
      const style = Object.entries(styleObject)
        .map(([k, v]) => `${k}:${v}`)
        .join('; ')
      element.setAttribute('style', (element.getAttribute('style')?.toString() || '') + ';' + style)
      element.classList.remove(className)
    },
  },

  data() {
    return {
      styles: {
        name: {
          // color: '#0C141A',
          color: 'inherit !important',
          padding: 0,
          margin: 0,
          'font-size': '16px !important',
          'font-weight': '400',
          'font-family': 'Arial,Helvetica,sans-serif !important',
          'mso-line-height-rule': 'exactly',
          'line-height': '100%',
        },
        default: {
          // color: '#0C141A',
          color: 'inherit !important',
          padding: 0,
          margin: 0,
          'font-size': '14px !important',
          'font-weight': '400',
          'font-family': 'Arial,Helvetica,sans-serif !important',
          'mso-line-height-rule': 'exactly',
          'line-height': '100%',
        },
        defaultSerif: {
          // color: '#0C141A',
          color: 'inherit !important',
          padding: 0,
          margin: 0,
          'font-size': '15px !important',
          'font-weight': '400',
          'font-family': '"Times New Roman",Times,serif !important',
          'mso-line-height-rule': 'exactly',
          'line-height': '100%',
        },
        defaultLink: {
          color: 'inherit !important',
          'text-decoration': 'underline !important',
        },
        addressLink: {
          color: 'inherit !important',
          'text-decoration': 'none !important',
        },
        small: {
          // color: '#0C141A',
          color: 'inherit !important',
          padding: 0,
          margin: 0,
          'font-size': '12px !important',
          'font-weight': '400',
          'font-family': 'Arial,Helvetica,sans-serif !important',
          'mso-line-height-rule': 'exactly',
          'line-height': '100%',
        },
        smallLineHeight: {
          'line-height': '17px !important',
          'mso-text-raise': '-2px !important',
        },
        smallLink: {
          // color: '#0C141A',
          color: 'inherit !important',
          'text-decoration': 'underline !important',
        },
        imgCircle: {
          // color: '#0C141A',
          width: '12px',
          height: '12px',
          display: 'block',
          margin: 0,
          padding: 0,
          outline: 'none',
          border: 'none',
          'line-height': '12px',
          'mso-line-height-rule': 'exactly',
        },
      },
    }
  },
}
</script>
